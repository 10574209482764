import { Box, Button, Grid, Typography } from "@mui/material";
import "./Filter.css";
import FilterTextField from "./FilterTextField";
import db from "../tiresDB";
import { useState } from "react";


const Filter = ({ tires, setSelectedSize, setSelectedSeason }) => {


  const [sizeFlt, setSizeFlt] = useState();
  const [seasonFlt, setSeasonFlt] = useState();


  const handleFilter = (e) => {
    e.preventDefault();

    console.log(tires);


    const filterMode = {
      size: sizeFlt,
      season: seasonFlt
    };

    localStorage.setItem("filterMode", JSON.stringify(filterMode));

     //@TODO: choose one way to pass variables to the filter
    setSelectedSize(sizeFlt);
    setSelectedSeason(seasonFlt);

  };


  return (
    <>
      <Box className="filter-box">
        <Typography  sx={{ color: "rgb(75,76,79)", textTransform: "uppercase" }} fontSize={"1.4rem"}> 
          Löydä sopivat renkaat
        </Typography>
       
        <form onSubmit={handleFilter}>
        <Grid 
          container 
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          >
            
            <FilterTextField tires={Array.from(db.sizes).sort()} name="koko" value={sizeFlt} setValue={setSizeFlt}/>         
            <FilterTextField tires={db.seasons} name="vuodenaika" value={seasonFlt} setValue={setSeasonFlt}/>
                      
        </Grid>

        <Grid 
          container 
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
          >

          <Grid item xs={6} sm={6} md={6} lg={4} 
                sx={{
                textAlign: "center",
                paddingTop: "4px",
                paddingBottom: "4px"
              }}>

            <Button variant="contained" type="submit"
              sx={{
                backgroundColor: "#F5F6F5",
                border: "1px",
                borderRadius: "3px",
                margin: "5px",
                color: "#47494a",
                width: "90%",
                marginTop: "10px",
                fontSize: "1rem",
                border: "ridge",
                ":hover": {
                  backgroundColor: "rgb(180, 192, 180)"
                }
              }}>
              etsiä
            </Button>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={2} 
                sx={{
                textAlign: "center",
                paddingTop: "4px",
                paddingBottom: "4px"
              }}>
            <Button variant="contained"
            onClick={() => window.location.reload(false)}
              sx={{
                color: "#47494a",
                borderRadius: "3px",
                backgroundColor: "#F5F6F5",
                margin: "5px",
                width: "40%",
                marginTop: "10px",
                fontSize: "1rem",
                border: "ridge",
                ":hover": {
                  backgroundColor: "#C58B8B"
                }
                }}>
                tyhjä
              </Button>
          </Grid>
        </Grid>

        </form>

      </Box>

    </>

  );
};

export default Filter;