import { Box, Grid } from "@mui/material";
import OneTire from "./OneTire";
import { useEffect, useState } from "react";

const RenkaatList = ({ tires, selectedModel, selectedSize, selectedSeason }) => {

  const[filteredList, setFilteredList] = useState(tires);

   //@TODO: use another way to get the variables for the filter
  //@TODO: use one function for the filter

  const filterByModel =(filteredData) => {
    if(!selectedModel){
      return filteredData;
    }

    const filteredTires = filteredData.filter(
      (tire) => tire.model.split(" ").indexOf(selectedModel) !== -1
    );
    return filteredTires;
  };

  ////////

  const filterBySize = (filteredData) => {
    if(!selectedSize) {
      return filteredData;
    }

    const filteredTires = filteredData.filter(
      (tire) => tire.size === selectedSize
    );
    return filteredTires;
  };

  const filterBySeason =(filteredData) => {
    if(!selectedSeason) {
      return filteredData;
    }

    const filteredTires = filteredData.filter(
      (tire) => tire.season === selectedSeason
    );
    return filteredTires;
  }


  useEffect(() => {

    var filteredData = filterByModel(tires);
    filteredData = filterBySize(filteredData);
    filteredData = filterBySeason(filteredData);
    setFilteredList(filteredData);
  }, [selectedModel, selectedSize, selectedSeason]);



  return (
    <>
    <Box sx={{ marginTop: "1rem", marginBottom: "4rem" }}>
  
      <Grid 
      container 
      direction="row"
      >
        {filteredList && filteredList.map(tire => (
        <Grid item xs={6} sm={4} md={3} lg={2} key={tire.id} 
        sx={{
          textAlign: "center"
        }}>
          <OneTire key={tire.id} tire={tire}/>
        </Grid>
        ))}

      </Grid>

    </Box>
    </>
    );
};

export default RenkaatList;