import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from "@mui/material";
import { useState } from "react";
import "./ImagesAll.css";


const ImagesAll = ({ images }) => {

    const[slide, setSlide]= useState(0);
    let objectsLen = 0;
    for (let i = 0; i < images.length; i ++){
        if (images[i] instanceof Object) {
            objectsLen++;
        }
    }


    const nextSlide = () => {
        setSlide(slide === Object.keys(images).length - 1 ? 0 : slide + 1);
    };

    const prevSlide = () => {
        setSlide(slide === 0 ? Object.keys(images).length - 1 : slide - 1);
    };

    return (
        <Box className="carousel">

        <ArrowBackIosIcon className={Object.keys(images).length > 1 ? "arrow arrow-left" : "hidden"} onClick={prevSlide} />

        {Object.values(images).map((item, idx) => {
            return <img src={item} alt="some" key={idx} 
                className={slide === idx ? "slide" : "slide-hidden"}
            />
        })}

        <ArrowForwardIosIcon className={Object.keys(images).length > 1 ?  "arrow arrow-right" : "hidden"} onClick={nextSlide}/>

        <span className="indicators">
            {Object.values(images).map((_, idx) => {
                return (
                    <button
                    key={idx}
                    onClick={() => setSlide(idx)}
                    className={
                        slide === idx ? "indicator" : "indicator indicator-inactive"
                    }
                    ></button>
                )
            })}
        </span>

    </Box>
    )
};

export default ImagesAll;