import db from "./components/tiresDB";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import TiresList from "./components/tiresList/TiresList.js";
import Filter from "./components/filter/Filter";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {

  const themeFilter = createTheme({
    typography: {
      fontFamily: [
        'DM Mono',
        'monospace',
      ].join(','),
    },
  });
//Azeret Mono
  const themeContent = createTheme({
    typography: {
      fontFamily: [
        // 'Cutive Mono',
        'monospace'
      ].join(','),
    },
  });


   //@TODO: remove variables from App.js and use another method
  const[selectedSize, setSelectedSize] = useState("");
  const[selectedSeason, setSelectedSeason] = useState("");


  return (
    <>
    <Header />
    <ThemeProvider theme={themeFilter}>
      <Filter tires={db.tiresDB} setSelectedSize={setSelectedSize} setSelectedSeason={setSelectedSeason}/>
    </ThemeProvider>
    <ThemeProvider theme={themeContent}>
      <TiresList tires={db.tiresDB} selectedSize={selectedSize} selectedSeason={selectedSeason}/>
    </ThemeProvider>
    <Footer />
    </>
  );
}

export default App;
