

let tiresAll = [
    {
        id: 10001,
        model: "Bridgestone Noranza ",
        size: "195/70/15C",
        season: "nasta",
        year: "2012",
        price: "70€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10001/mic3.jpg"),
            image2: require("../components/images/10001/mic2.jpg"),
            image3: require("../components/images/10001/mic1.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
        id: 10002,
        model: "Nokian Hakkapeliitta R3",
        size: "225/50/18",
        season: "kitka",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10002/mic1.jpg"),
            image2: require("../components/images/10002/mic2.jpg"),
            image3: require("../components/images/10002/mic3.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
        id: 10003,
        model: "Nokian Hakkapeliitta R3",
        size: "235/45/18",
        season: "kitka",
        year: "2019",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10003/mic1.jpg"),
            image2: require("../components/images/10003/mic2.jpg"),
            image3: require("../components/images/10003/mic3.jpg")
        },
        quantity: 2,
        hidden: false
    },
    {
    id: 10004,
    model: "Continental IceContact 7",
    size: "235/45/18",
    season: "kitka",
    year: "2020",
    price: "80€",
    pinta: "6mm",
    images: {
        image1: require("../components/images/10004/mic1.jpg"),
        image2: require("../components/images/10004/mic2.jpg"),
        image3: require("../components/images/10004/mic3.jpg")
    },
    quantity: 2,
    hidden: true
    },
    {
        id: 10005,
        model: "",
        size: "235/45/18",
        season: "kitka",
        year: "2020",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10005/mic1.jpg"),
            image2: require("../components/images/10005/mic2.jpg"),
            image3: require("../components/images/10005/mic3.jpg")
        },
        quantity: 2,
        hidden: false
        },
        {
            id: 10006,
            model: "Nokian Hakkapeliitta R3 Run flat",
            size: "225/50/17",
            season: "kitka",
            year: "2018",
            price: "170€",
            pinta: "7,5mm",
            images: {
                image1: require("../components/images/10006/mic1.jpg"),
                image2: require("../components/images/10006/mic2.jpg"),
                image3: require("../components/images/10006/mic3.jpg")
            },
            quantity: 4,
            hidden: true
            },
            {
                id: 10007,
                model: "",
                size: "235/35/19",
                season: "nasta",
                year: "2019",
                price: "170€",
                pinta: "8,5mm",
                images: {
                    image1: require("../components/images/10007/mic1.jpg"),
                    image2: require("../components/images/10007/mic2.jpg"),
                    image3: require("../components/images/10007/mic3.jpg")
                },
                quantity: 4,
                hidden: false
                },
                {
                    id: 10008,
                    model: "Continental",
                    size: "245/45/19",
                    season: "kitka",
                    year: "2016",
                    price: "70€",
                    pinta: "6,5mm",
                    images: {
                        image1: require("../components/images/10008/mic1.jpg"),
                        image2: require("../components/images/10008/mic2.jpg"),
                        image3: require("../components/images/10008/mic3.jpg")
                    },
                    quantity: 2,
                    hidden: false
                    },
                {
                    id: 10009,
                    model: "Continental",
                    size: "245/35/19",
                    season: "kitka",
                    year: "2021",
                    price: "150€",
                    pinta: "5,5mm",
                    images: {
                        image1: require("../components/images/10009/mic1.jpg"),
                        image2: require("../components/images/10009/mic2.jpg"),
                        image3: require("../components/images/10009/mic3.jpg")
                    },
                    quantity: 4,
                    hidden: false
                    },
    {
        id: 10010,
        model: "Nokian Hakkapeliitta CR3",
        size: "205/70/15C",
        season: "nasta",
        year: "2017",
        price: "100€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10010/mic1.jpg"),
            image2: require("../components/images/10010/mic2.jpg"),
            image3: require("../components/images/10010/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10011,
        model: "Continental",
        size: "235/40/19",
        season: "kitka",
        year: "2019",
        price: "180€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10011/mic1.jpg"),
            image2: require("../components/images/10011/mic2.jpg"),
            image3: require("../components/images/10011/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10012,
        model: "Viatti",
        size: "255/45/18",
        season: "nasta",
        year: "2020",
        price: "160€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10012/mic1.jpg"),
            image2: require("../components/images/10012/mic2.jpg"),
            image3: require("../components/images/10012/mic3.jpg"),
            image4: require("../components/images/10012/mic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10013,
        model: "Nokian Hakkapeliitta R3",
        size: "225/60/18",
        season: "kitka",
        year: "2016",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10013/mic1.jpg"),
            image2: require("../components/images/10013/mic2.jpg"),
            image3: require("../components/images/10013/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10014,
        model: "Michelin X Green",
        size: "245/45/18",
        season: "kitka",
        year: "",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10014/mic1.jpg"),
            image2: require("../components/images/10014/mic2.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10015,
        model: "Continental Viking Contact 7",
        size: "245/45/18",
        season: "kitka",
        year: "2018",
        price: "120€",
        pinta: "6,2mm",
        images: {
            image1: require("../components/images/10015/mic1.jpg"),
            image2: require("../components/images/10015/mic2.jpg"),
            image3: require("../components/images/10015/mic3.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10016,
        model: "Nokian Hakkapeliitta 8",
        size: "205/60/16",
        season: "nasta",
        year: "2015",
        price: "80€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10016/mic1.jpg"),
            image2: require("../components/images/10016/mic2.jpg"),
            image3: require("../components/images/10016/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10017,
        model: "Nokian Hakkapeliitta C3",
        size: "195/70/15C",
        season: "nasta",
        year: "2016",
        price: "80€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10017/mic1.jpg"),
            image2: require("../components/images/10017/mic2.jpg"),
            image3: require("../components/images/10017/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10018,
        model: "Continental",
        size: "195/65/15",
        season: "nasta",
        year: "2013",
        price: "120€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10018/mic1.jpg"),
            image2: require("../components/images/10018/mic2.jpg"),
            image3: require("../components/images/10018/mic3.jpg")
            },
        quantity: 4,
        hidden: true
    },
    {
        id: 10019,
        model: "Nokian Hakkapeliitta 7",
        size: "175/65/15",
        season: "nasta",
        year: "2013",
        price: "50€",
        pinta: "7,3mm",
        images: {
            image1: require("../components/images/10019/mic1.jpg"),
            image2: require("../components/images/10019/mic2.jpg"),
            image3: require("../components/images/10019/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10020,
        model: "Bridgestone Nordaza",
        size: "205/65/16C",
        season: "nasta",
        year: "2019",
        price: "180€",
        pinta: "8,5mm",
        images: {
            image1: require("../components/images/10020/mic1.jpg"),
            image2: require("../components/images/10020/mic2.jpg"),
            image3: require("../components/images/10020/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10021,
        model: "Nokian Hakkapeliitta R3",
        size: "205/55/16",
        season: "kitka",
        year: "2021",
        price: "70€",
        pinta: "7,3mm",
        images: {
            image1: require("../components/images/10021/mic1.jpg"),
            image2: require("../components/images/10021/mic2.jpg"),
            image3: require("../components/images/10021/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10022,
        model: "Continental 7",
        size: "225/55/18",
        season: "kitka",
        year: "2021",
        price: "170€",
        pinta: "7,3mm",
        images: {
            image1: require("../components/images/10022/mic1.jpg"),
            image2: require("../components/images/10022/mic2.jpg"),
            image3: require("../components/images/10022/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10023,
        model: "Michelin x-ice 4",
        size: "235/45/18",
        season: "nasta",
        year: "2021",
        price: "90€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10023/mic1.jpg"),
            image2: require("../components/images/10023/mic2.jpg"),
            image3: require("../components/images/10023/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10024,
        model: "Continental ice contact 2",
        size: "185/60/15",
        season: "nasta",
        year: "2018",
        price: "120€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10024/mic1.jpg"),
            image2: require("../components/images/10024/mic2.jpg"),
            image3: require("../components/images/10024/mic3.jpg"),
            image4: require("../components/images/10024/mic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10025,
        model: "Michelin x-ice 4",
        size: "205/55/16",
        season: "nasta",
        year: "2020",
        price: "70€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10025/mic1.jpg"),
            image2: require("../components/images/10025/mic2.jpg"),
            image3: require("../components/images/10025/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10026,
        model: "Pirelli",
        size: "225/50/17",
        season: "nasta",
        year: "2019",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10026/mic1.jpg"),
            image2: require("../components/images/10026/mic2.jpg"),
            image3: require("../components/images/10026/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10027,
        model: "Nokian Hakkapeliitta 8",
        size: "205/55/17",
        season: "nasta",
        year: "2017",
        price: "100€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10027/mic1.jpg"),
            image2: require("../components/images/10027/mic2.jpg"),
            image3: require("../components/images/10027/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10028,
        model: "Continental 2",
        size: "215/60/16",
        season: "nasta",
        year: "2016",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10028/mic1.jpg"),
            image2: require("../components/images/10028/mic2.jpg"),
            image3: require("../components/images/10028/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10029,
        model: "Continental 7",
        size: "205/55/16",
        season: "kitka",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10029/mic1.jpg"),
            image2: require("../components/images/10029/mic2.jpg"),
            image3: require("../components/images/10029/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10030,
        model: "Michelin x ice 4",
        size: "245/45/18",
        season: "nasta",
        year: "2020",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10030/mic1.jpg"),
            image2: require("../components/images/10030/mic2.jpg"),
            image3: require("../components/images/10030/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10031,
        model: "Michelin",
        size: "225/45/17",
        season: "kitka",
        year: "2020",
        price: "80€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10031/mic1.jpg"),
            image2: require("../components/images/10031/mic2.jpg"),
            image3: require("../components/images/10031/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10032,
        model: "Nokian Hakkapeliitta C",
        size: "215/65/16",
        season: "nasta",
        year: "2022",
        price: "100€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10032/mic1.jpg"),
            image2: require("../components/images/10032/mic2.jpg"),
            image3: require("../components/images/10032/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10033,
        model: "Continental",
        size: "225/45/17",
        season: "kitka",
        year: "2017",
        price: "70€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10033/mic1.jpg"),
            image2: require("../components/images/10033/mic2.jpg"),
            image3: require("../components/images/10033/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10034,
        model: "Michelin x ice 4",
        size: "245/45/17",
        season: "nasta",
        year: "2018",
        price: "80€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10034/mic1.jpg"),
            image2: require("../components/images/10034/mic2.jpg"),
            image3: require("../components/images/10034/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10035,
        model: "Michelin 4",
        size: "245/45/18",
        season: "nasta",
        year: "2020",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10035/mic1.jpg"),
            image2: require("../components/images/10035/mic2.jpg"),
            image3: require("../components/images/10035/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10036,
        model: "Nokian Hakkapeliitta C3",
        size: "215/60/16C",
        season: "nasta",
        year: "2020",
        price: "100€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10036/mic1.jpg"),
            image2: require("../components/images/10036/mic2.jpg"),
            image3: require("../components/images/10036/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10037,
        model: "Continental 2",
        size: "235/55/18",
        season: "nasta",
        year: "2019",
        price: "200€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10037/mic1.jpg"),
            image2: require("../components/images/10037/mic2.jpg"),
            image3: require("../components/images/10037/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10038,
        model: "Kuhmo",
        size: "225/45/17",
        season: "nasta",
        year: "2020",
        price: "100€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10038/mic1.jpg"),
            image2: require("../components/images/10038/mic2.jpg"),
            image3: require("../components/images/10038/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10039,
        model: "Nokian Hakkapeliitta R3",
        size: "225/50/17",
        season: "kitka",
        year: "2019",
        price: "170€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10039/mic1.jpg"),
            image2: require("../components/images/10039/mic2.jpg"),
            image3: require("../components/images/10039/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10040,
        model: "Pirrelli  Zero",
        size: "225/50/17",
        season: "nasta",
        year: "2014",
        price: "120€",
        pinta: "7,7mm",
        images: {
            image1: require("../components/images/10040/mic1.jpg"),
            image2: require("../components/images/10040/mic2.jpg"),
            image3: require("../components/images/10040/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10041,
        model: "Continental 2",
        size: "205/55/16",
        season: "nasta",
        year: "2015",
        price: "120€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10041/mic1.jpg"),
            image2: require("../components/images/10041/mic2.jpg"),
            image3: require("../components/images/10041/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10042,
        model: "Gislaved",
        size: "275/40/20",
        season: "nasta",
        year: "2019",
        price: "250€",
        pinta: "9mm",
        images: {
            image1: require("../components/images/10042/mic1.jpg"),
            image2: require("../components/images/10042/mic2.jpg"),
            image3: require("../components/images/10042/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10043,
        model: "",
        size: "225/45/18",
        season: "kitka",
        year: "2019",
        price: "70€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10043/mic1.jpg"),
            image2: require("../components/images/10043/mic2.jpg"),
            image3: require("../components/images/10043/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10044,
        model: "Michelin x4",
        size: "245/45/17",
        season: "nasta",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10044/mic1.jpg"),
            image2: require("../components/images/10044/mic2.jpg"),
            image3: require("../components/images/10044/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10045,
        model: "Bridgestone",
        size: "215/50/17",
        season: "nasta",
        year: "2016",
        price: "70€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10045/mic1.jpg"),
            image2: require("../components/images/10045/mic2.jpg"),
            image3: require("../components/images/10045/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10046,
        model: "Continental 2",
        size: "235/45/18",
        season: "nasta",
        year: "2017",
        price: "180€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10046/mic1.jpg"),
            image2: require("../components/images/10046/mic2.jpg"),
            image3: require("../components/images/10046/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10047,
        model: "Continental 7",
        size: "225/45/17",
        season: "kitka",
        year: "2018",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10047/mic1.jpg"),
            image2: require("../components/images/10047/mic2.jpg"),
            image3: require("../components/images/10047/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10048,
        model: "Continental 2",
        size: "225/55/18",
        season: "nasta",
        year: "2019",
        price: "200€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10048/mic1.jpg"),
            image2: require("../components/images/10048/mic2.jpg"),
            image3: require("../components/images/10048/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10049,
        model: "Continental 2",
        size: "205/55/16",
        season: "nasta",
        year: "2015",
        price: "140€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10049/mic1.jpg"),
            image2: require("../components/images/10049/mic2.jpg"),
            image3: require("../components/images/10049/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10050,
        model: "",
        size: "225/45/17",
        season: "nasta",
        year: "2016",
        price: "180€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10050/mic1.jpg"),
            image2: require("../components/images/10050/mic2.jpg"),
            image3: require("../components/images/10050/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10051,
        model: "Michelin x4",
        size: "205/55/16",
        season: "nasta",
        year: "2021",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10051/mic1.jpg"),
            image2: require("../components/images/10051/mic2.jpg"),
            image3: require("../components/images/10051/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10052,
        model: "Continental 2",
        size: "205/60/16",
        season: "nasta",
        year: "2017",
        price: "70€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10052/mic1.jpg"),
            image2: require("../components/images/10052/mic2.jpg"),
            image3: require("../components/images/10052/mic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10053,
        model: "Continental 7",
        size: "225/55/17",
        season: "kitka",
        year: "2020",
        price: "160€",
        pinta: "8mm",
        images: {
            image1: require("../components/images/10053/mic1.jpg"),
            image2: require("../components/images/10053/mic2.jpg"),
            image3: require("../components/images/10053/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10054,
        model: "Michelin x4",
        size: "195/65/15",
        season: "nasta",
        year: "2018",
        price: "130€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10054/mic1.jpg"),
            image2: require("../components/images/10054/mic2.jpg"),
            image3: require("../components/images/10054/mic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10102,
        model: "Michelin Primacy 4",
        size: "215/55/17",
        season: "kesä",
        year: "2019",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10102/pic1.jpg"),
            image2: require("../components/images/10102/pic2.jpg"),
            image3: require("../components/images/10102/pic3.jpg"),
            image4: require("../components/images/10102/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10103,
        model: "Continental PremiumContact 6",
        size: "225/40/18",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "92Y",
        price: "50€",
        pinta: "3mm",
        images: {
            image1: require("../components/images/10103/pic1.jpg"),
            image2: require("../components/images/10103/pic2.jpg"),
            image3: require("../components/images/10103/pic3.jpg"),
            image4: require("../components/images/10103/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10106,
        model: "Triangle Sportex",
        size: "225/35/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "88Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10106/pic1.jpg"),
            image2: require("../components/images/10106/pic2.jpg"),
            image3: require("../components/images/10106/pic3.jpg"),
            image4: require("../components/images/10106/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10107,
        model: "Triangle Sportex",
        size: "275/30/19",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10107/pic1.jpg"),
            image2: require("../components/images/10107/pic2.jpg"),
            image3: require("../components/images/10107/pic3.jpg"),
            image4: require("../components/images/10107/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10108,
        model: "Landsail  Sentury Quirin 990",
        size: "225/45/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "96Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10108/pic1.jpg"),
            image2: require("../components/images/10108/pic2.jpg"),
            image3: require("../components/images/10108/pic3.jpg"),
            image4: require("../components/images/10108/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10109,
        model: "Michelin  Pilot Sport 4s",
        size: "255/35/19",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "96Y",
        price: "150€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10109/pic1.jpg"),
            image2: require("../components/images/10109/pic2.jpg"),
            image3: require("../components/images/10109/pic3.jpg"),
            image4: require("../components/images/10109/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10110,
        model: "Hankook Ventus Prime 3",
        size: "205/60/16",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "92H",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10110/pic1.jpg"),
            image2: require("../components/images/10110/pic2.jpg"),
            image3: require("../components/images/10110/pic3.jpg"),
            image4: require("../components/images/10110/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10112,
        model: "Vredestein Ultrac Vorti",
        size: "275/35/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "102Y",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10112/pic1.jpg"),
            image2: require("../components/images/10112/pic2.jpg"),
            image3: require("../components/images/10112/pic3.jpg"),
            image4: require("../components/images/10112/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10113,
        model: "Linglong Sportmaster",
        size: "315/35/20",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "110Y",
        price: "100€",
        pinta: "3,5mm",
        images: {
            image1: require("../components/images/10113/pic1.jpg"),
            image2: require("../components/images/10113/pic2.jpg"),
            image3: require("../components/images/10113/pic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10114,
        model: "Pirelli P Zero",
        size: "275/40/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "106W",
        price: "100€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10114/pic1.jpg"),
            image2: require("../components/images/10114/pic2.jpg"),
            image3: require("../components/images/10114/pic3.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10116,
        model: "Continental VikingContact 7",
        size: "215/55/17",
        season: "kitka",
        year: "2019",
        loadSpdIdx: "98T",
        price: "170€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10116/pic1.jpg"),
            image2: require("../components/images/10116/pic2.jpg"),
            image3: require("../components/images/10116/pic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10117,
        model: "Nexen Winguard WinSpike WH62",
        size: "195/65/15",
        season: "nasta",
        year: "2015",
        loadSpdIdx: "",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10117/pic1.jpg"),
            image2: require("../components/images/10117/pic2.jpg"),
            image3: require("../components/images/10117/pic3.jpg"),
            image4: require("../components/images/10117/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10118,
        model: "Azenis FK510",
        size: "235/40/19",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96Y",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10118/pic1.jpg"),
            image2: require("../components/images/10118/pic2.jpg"),
            image3: require("../components/images/10118/pic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10119,
        model: "Linglong Green-Max",
        size: "215/45/17",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "91W",
        price: "50€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10119/pic1.jpg"),
            image2: require("../components/images/10119/pic2.jpg"),
            image3: require("../components/images/10119/pic3.jpg"),
            image4: require("../components/images/10119/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10120,
        model: "Michelin  X-Ice North 4",
        size: "215/60/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "99T",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10120/pic1.jpg"),
            image2: require("../components/images/10120/pic2.jpg"),
            image3: require("../components/images/10120/pic3.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10121,
        model: "Bridgestone Noranza",
        size: "215/65/16",
        season: "nasta",
        year: "2016",
        loadSpdIdx: "102T",
        price: "80€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10121/pic1.jpg"),
            image2: require("../components/images/10121/pic2.jpg"),
            image3: require("../components/images/10121/pic3.jpg"),
            image4: require("../components/images/10121/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10130,
        model: "Michelin  Primacy 4",
        size: "205/55/16",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "91V",
        price: "70€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10130/pic1.jpg"),
            image2: require("../components/images/10130/pic2.jpg"),
            image3: require("../components/images/10130/pic3.jpg"),
            image4: require("../components/images/10130/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10131,
        model: "Minerva Ecospeed 2 SUV",
        size: "215/55/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10131/pic1.jpg"),
            image2: require("../components/images/10131/pic2.jpg"),
            image3: require("../components/images/10131/pic3.jpg"),
            //image4: require("../components/images/10130/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10132,
        model: "Continental  IceContact 2",
        size: "235/55/18",
        season: "nasta",
        year: "2020",
        loadSpdIdx: "104T",
        price: "200€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10132/pic1.jpg"),
            image2: require("../components/images/10132/pic2.jpg"),
            image3: require("../components/images/10132/pic3.jpg"),
            image4: require("../components/images/10132/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10133,
        model: "Nokian Hakkapeliitta 9",
        size: "215/50/17",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "",
        price: "150€",
        pinta: "6-8mm",
        images: {
            image1: require("../components/images/10133/pic1.jpg"),
            image2: require("../components/images/10133/pic2.jpg"),
            image3: require("../components/images/10133/pic3.jpg"),
            image4: require("../components/images/10133/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10134,
        model: "Nokian Hakkapeliitta 9",
        size: "205/60/16",
        season: "nasta",
        year: "2018",
        loadSpdIdx: "",
        price: "120€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10134/pic1.jpg"),
            image2: require("../components/images/10134/pic2.jpg"),
            image3: require("../components/images/10134/pic3.jpg"),
            image4: require("../components/images/10134/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10135,
        model: "Kumho Ecsta PS91",
        size: "255/35/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "96Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10135/pic1.jpg"),
            image2: require("../components/images/10135/pic2.jpg"),
            image3: require("../components/images/10135/pic3.jpg"),
            //image4: require("../components/images/10135/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10136,
        model: "Gislaved Ultra Speed 2",
        size: "225/45/17",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "91Y",
        price: "130€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10136/pic1.jpg"),
            image2: require("../components/images/10136/pic2.jpg"),
            image3: require("../components/images/10136/pic3.jpg"),
            image4: require("../components/images/10136/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10137,
        model: "Linglong  Green-max HP 010",
        size: "185/60/15",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "88H",
        price: "40€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10137/pic1.jpg"),
            image2: require("../components/images/10137/pic2.jpg"),
            image3: require("../components/images/10137/pic3.jpg"),
            image4: require("../components/images/10137/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10138,
        model: "Continental  EcoContact 6",
        size: "195/60/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "96H",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10138/pic1.jpg"),
            image2: require("../components/images/10138/pic2.jpg"),
            image3: require("../components/images/10138/pic3.jpg"),
            image4: require("../components/images/10138/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10139,
        model: "Continental  PremiumContact 7",
        size: "225/55/18",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "96H",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10139/pic1.jpg"),
            image2: require("../components/images/10139/pic2.jpg"),
            image3: require("../components/images/10139/pic3.jpg"),
            image4: require("../components/images/10139/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10140,
        model: "Continental  EcoContact 6",
        size: "245/40/18",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10140/pic1.jpg"),
            image2: require("../components/images/10140/pic2.jpg"),
            image3: require("../components/images/10140/pic3.jpg"),
            image4: require("../components/images/10140/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10141,
        model: "Antares Comfort A5",
        size: "255/35/20",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "97W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10141/pic1.jpg"),
            image2: require("../components/images/10141/pic2.jpg"),
            image3: require("../components/images/10141/pic3.jpg"),
            image4: require("../components/images/10141/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10142,
        model: "Kleber Transpro",
        size: "225/65/16C",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10142/pic1.jpg"),
            image2: require("../components/images/10142/pic2.jpg"),
            image3: require("../components/images/10142/pic3.jpg"),
            image4: require("../components/images/10142/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10143,
        model: "Michelin  CrossClimate",
        size: "225/45/17",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "",
        price: "50€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10143/pic1.jpg"),
            image2: require("../components/images/10143/pic2.jpg"),
            image3: require("../components/images/10143/pic3.jpg"),
            //image4: require("../components/images/10143/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10144,
        model: "Michelin  Pilot Sport 3",
        size: "245/45/19",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "102Y",
        price: "150€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10144/pic1.jpg"),
            image2: require("../components/images/10144/pic2.jpg"),
            image3: require("../components/images/10144/pic3.jpg"),
            //image4: require("../components/images/10144/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10145,
        model: "Toyo Proxes T1 Sport",
        size: "225/55/17",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "97V",
        price: "150€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10145/pic1.jpg"),
            image2: require("../components/images/10145/pic2.jpg"),
            image3: require("../components/images/10145/pic3.jpg"),
            image4: require("../components/images/10145/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10146,
        model: "Triangle Sportex",
        size: "215/55/17",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "94W",
        price: "50€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10146/pic1.jpg"),
            image2: require("../components/images/10146/pic2.jpg"),
            image3: require("../components/images/10146/pic3.jpg"),
            image4: require("../components/images/10146/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10148,
        model: "Kumho Solus KH17",
        size: "215/50/17",
        season: "kesä",
        year: "2011",
        loadSpdIdx: "91V",
        price: "70€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10148/pic1.jpg"),
            image2: require("../components/images/10148/pic2.jpg"),
            image3: require("../components/images/10148/pic3.jpg"),
            //image4: require("../components/images/10148/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10149,
        model: "Hankook Winter I*cept Evo 2",
        size: "255/45/19",
        season: "kitka",
        year: "2020",
        loadSpdIdx: "104V",
        price: "170€",
        pinta: "5,5mm",
        images: {
            image1: require("../components/images/10149/pic1.jpg"),
            image2: require("../components/images/10149/pic2.jpg"),
            image3: require("../components/images/10149/pic3.jpg"),
            image4: require("../components/images/10149/pic4.jpg")
            },
        quantity: 4,
        hidden: false
    },
    {
        id: 10150,
        model: "Michelin  Pilot Sport 4",
        size: "245/40/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10150/pic1.jpg"),
            image2: require("../components/images/10150/pic2.jpg"),
            image3: require("../components/images/10150/pic3.jpg"),
            image4: require("../components/images/10150/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10151,
        model: "Michelin  Primacy 4",
        size: "225/50/17",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "98Y",
        price: "60€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10151/pic1.jpg"),
            image2: require("../components/images/10151/pic2.jpg"),
            image3: require("../components/images/10151/pic3.jpg"),
            image4: require("../components/images/10151/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10152,
        model: "Continental  ContiSportContact 3",
        size: "245/40/18",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "97Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10152/pic1.jpg"),
            image2: require("../components/images/10152/pic2.jpg"),
            image3: require("../components/images/10152/pic3.jpg"),
            image4: require("../components/images/10152/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10153,
        model: "Hankook Ventus S1 Evo 2",
        size: "275/30/19",
        season: "kesä",
        year: "2018",
        loadSpdIdx: "96Y",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10153/pic1.jpg"),
            image2: require("../components/images/10153/pic2.jpg"),
            image3: require("../components/images/10153/pic3.jpg"),
            image4: require("../components/images/10153/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10154,
        model: "Bridgestone Turanza T005",
        size: "195/55/16",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "87H",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10154/pic1.jpg"),
            image2: require("../components/images/10154/pic2.jpg"),
            image3: require("../components/images/10154/pic3.jpg"),
            image4: require("../components/images/10154/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10155,
        model: "Continental  EcoContact 6",
        size: "195/55/16",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "87V",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10155/pic1.jpg"),
            image2: require("../components/images/10155/pic2.jpg"),
            image3: require("../components/images/10155/pic3.jpg"),
            //image4: require("../components/images/10155/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10156,
        model: "Michelin  Primacy 3",
        size: "245/45/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "100Y",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10156/pic1.jpg"),
            image2: require("../components/images/10156/pic2.jpg"),
            image3: require("../components/images/10156/pic3.jpg"),
            image4: require("../components/images/10156/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10157,
        model: "Arivo Ultra ARZ5",
        size: "275/30/20",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "97W",
        price: "100€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10157/pic1.jpg"),
            image2: require("../components/images/10157/pic2.jpg"),
            image3: require("../components/images/10157/pic3.jpg"),
            image4: require("../components/images/10157/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10158,
        model: "Michelin  Primacy 4",
        size: "195/65/15",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "91H",
        price: "50€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10158/pic1.jpg"),
            image2: require("../components/images/10158/pic2.jpg"),
            image3: require("../components/images/10158/pic3.jpg"),
            image4: require("../components/images/10158/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10159,
        model: "Michelin  Pilot Sport 4",
        size: "235/45/18",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "",
        price: "80€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10159/pic1.jpg"),
            image2: require("../components/images/10159/pic2.jpg"),
            image3: require("../components/images/10159/pic3.jpg"),
            image4: require("../components/images/10159/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10160,
        model: "Michelin  Pilot Sport 4",
        size: "235/45/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "98Y",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10160/pic1.jpg"),
            image2: require("../components/images/10160/pic2.jpg"),
            image3: require("../components/images/10160/pic3.jpg"),
            image4: require("../components/images/10160/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10161,
        model: "Michelin  Primacy 4",
        size: "235/45/18",
        season: "kesä",
        year: "2021",
        loadSpdIdx: "98W",
        price: "80€",
        pinta: "4mm",
        images: {
            image1: require("../components/images/10161/pic1.jpg"),
            image2: require("../components/images/10161/pic2.jpg"),
            image3: require("../components/images/10161/pic3.jpg"),
            image4: require("../components/images/10161/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10162,
        model: "Michelin  Primacy 4",
        size: "225/45/17",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "94V",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10162/pic1.jpg"),
            image2: require("../components/images/10162/pic2.jpg"),
            image3: require("../components/images/10162/pic3.jpg"),
            //image4: require("../components/images/10162/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10163,
        model: "GoodYear Eagle F1 Asymmetric 3",
        size: "275/40/18",
        season: "kesä",
        year: "2022",
        loadSpdIdx: "103Y",
        price: "100€",
        pinta: "6mm",
        images: {
            image1: require("../components/images/10163/pic1.jpg"),
            image2: require("../components/images/10163/pic2.jpg"),
            image3: require("../components/images/10163/pic3.jpg"),
            image4: require("../components/images/10163/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10164,
        model: "Continental  ContiPremiumContact 2",
        size: "205/60/16",
        season: "kesä",
        year: "2014",
        loadSpdIdx: "92H",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10164/pic1.jpg"),
            image2: require("../components/images/10164/pic2.jpg"),
            image3: require("../components/images/10164/pic3.jpg"),
            image4: require("../components/images/10164/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10165,
        model: "Bridgestone Ecopia EP150",
        size: "185/55/16",
        season: "kesä",
        year: "2019",
        loadSpdIdx: "83V",
        price: "50€",
        pinta: "6,5mm",
        images: {
            image1: require("../components/images/10165/pic1.jpg"),
            image2: require("../components/images/10165/pic2.jpg"),
            image3: require("../components/images/10165/pic3.jpg"),
            image4: require("../components/images/10165/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10166,
        model: "GoodRide Radial RP28",
        size: "165/65/13",
        season: "kesä",
        year: "2016",
        loadSpdIdx: "77T",
        price: "50€",
        pinta: "7mm",
        images: {
            image1: require("../components/images/10166/pic1.jpg"),
            image2: require("../components/images/10166/pic2.jpg"),
            image3: require("../components/images/10166/pic3.jpg"),
            image4: require("../components/images/10166/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10167,
        model: "Michelin  Primacy 4",
        size: "255/45/20",
        season: "kesä",
        year: "2020",
        loadSpdIdx: "",
        price: "80€",
        pinta: "4,5mm",
        images: {
            image1: require("../components/images/10167/pic1.jpg"),
            image2: require("../components/images/10167/pic2.jpg"),
            image3: require("../components/images/10167/pic3.jpg"),
            image4: require("../components/images/10167/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10168,
        model: "Continental  VanContact Eco",
        size: "215/60/17C",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "107T",
        price: "100€",
        pinta: "7,5mm",
        images: {
            image1: require("../components/images/10168/pic1.jpg"),
            image2: require("../components/images/10168/pic2.jpg"),
            image3: require("../components/images/10168/pic3.jpg"),
            image4: require("../components/images/10168/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10169,
        model: "Nokian Hakka Blue 3",
        size: "215/55/17",
        season: "kesä",
        year: "2023",
        loadSpdIdx: "",
        price: "70€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10169/pic1.jpg"),
            image2: require("../components/images/10169/pic2.jpg"),
            image3: require("../components/images/10169/pic3.jpg"),
            //image4: require("../components/images/10169/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },
    {
        id: 10170,
        model: "Nokian Nordman S SUV",
        size: "235/55/17",
        season: "kesä",
        year: "2017",
        loadSpdIdx: "99H",
        price: "60€",
        pinta: "5mm",
        images: {
            image1: require("../components/images/10170/pic1.jpg"),
            image2: require("../components/images/10170/pic2.jpg"),
            image3: require("../components/images/10170/pic3.jpg"),
            image4: require("../components/images/10170/pic4.jpg")
            },
        quantity: 2,
        hidden: false
    },



    // {
    //     id: ,
    //     model: "",
    //     size: "//",
    //     season: "",
    //     year: "",
    //     loadSpdIdx: "",
    //     price: "€",
    //     pinta: "mm",
    //     images: {
    //         image1: require("../components/images/100/mic1.JPG"),
    //         image2: require("../components/images/100/mic2.JPG"),
    //         image3: require("../components/images/100/mic3.JPG"),
    //         image4: require("../components/images/100/mic4.JPG")
    //     },
    //     quantity: ,
    //     hidden: false
    // },

];

let models = new Set();
tiresAll.map(h => models.add(h.model));/////

let sizes = new Set();
tiresAll.map(h => sizes.add(h.size));

let seasons = new Set();
tiresAll.map(h => seasons.add(h.season));


let tires_visible = [];
tiresAll.map(h=> 
    h.hidden === false ?
    tires_visible.push(h)
        : null
    )

    tires_visible.sort((a, b) => a.id > b.id ? 1 : -1);


const db = { tiresDB: tires_visible, models: models, sizes: sizes, seasons: seasons};


export default db;