import { Box, SwipeableDrawer, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ImagesAll from "../images/ImagesAll";
import "./Common.css";

const DrawerBottom = ({ open, onClose, onOpen, attachment }) => {


  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        className: "drawer",
        sx: {
          height: 750
        }
      }}
    >
      <CloseIcon 
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem"
      }}/>

      <Box className="header-box">
        <Typography sx={{ fontWeight: 'bold', fontSize: "20px", marginTop: "5px" }}>
          {attachment.model}
        </Typography>

        <Typography>
          Koko: {attachment.size}  {attachment.season}
        </Typography>
      </Box>


        <Box className="image-box">
          <ImagesAll images={attachment.images}/>
          <Typography variant="body1"
            sx={{
              fontSize: "0.8rem",
              float: "left",
              paddingLeft: "2rem",
              position: "absolute"
            }}>
              
            {attachment.id}
          </Typography>
        </Box>


      <Box className="footer-box">
        <Typography>
          Vuosi: {attachment.year}
        </Typography>

        <Typography>
          Pintaa: {attachment.pinta}
        </Typography>

        <Typography className="kpl">
          {attachment.quantity}kpl
        </Typography>

        <Typography variant="h4" className="price">
              {attachment.price} 
        </Typography>
      </Box>

    </SwipeableDrawer>
  );
};

export default DrawerBottom;
