import { TextField, MenuItem, Grid }from "@mui/material";
import "./Filter.css";


const TextFieldMui = ({ tires, name, value, setValue }) => {



    return (
      
      <Grid item xs={6} sm={4} md={3} lg={2} 
      sx={{
      textAlign: "center",
      paddingTop: "4px",
      paddingBottom: "4px"
    }}>

        <TextField
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        select
        name="placeList"
        label={name}
        InputLabelProps={{
          style: { color: "rgb(75,76,79)", fontSize: "1rem" }
        }}
        sx={{
          width: "90%",
          "& fieldset": {
            borderRadius: "3px",
            borderColor: "#4B4C4F"
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0E6163"
          },
          "& .MuiInputBase-root": {
            color: "#4A4E4D",
            textTransform: "uppercase"
          }
        }}
      >

        {Array.from(tires).map(item =>
          <MenuItem sx={{ textTransform: "uppercase", justifyContent: "center"}}
            className="menuItem"
            key={item}
            value={item}
          >
            {item}
          </MenuItem>
        )}


      </TextField>
      </Grid>

    );
};

export default TextFieldMui;