import { Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";


const Info = () => {
    

    return (
        <Card variant="elevation" elevation={12}
        sx={{
          textAlign: "center",
          minHeight: 40,
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          weight: "80%",
          backgroundColor: "rgb(180, 192, 180)",
          boxShadow: " 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)",
          marginTop: "2px",
          padding: "2px",
          fontFamily: "serif",
          fontSize: "1rem",
          margin: "1rem",
          color: "#1A1A1A",
          opacity: "90%",
          borderRadius: "5px"
            
        }}>
        
          

          <Typography variant="h6" fontFamily={"serif"} fontSize={"1.1rem"} lineHeight={"1"} >
            Ota meihin yhteyttä toimitusmahdollisuudesta<br/>
            sekä suurten määrien tilaamisesta
          </Typography>    
    
        </Card>
    );
};

export default Info;